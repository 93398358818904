<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('unidentified_payments')"
                        :isNewButton="checkPermission('unidentifiedpayment_store')"
                        @new-button-click="createFormShow"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('unidentified_payments')"
                              :isNewButton="checkPermission('unidentifiedpayment_store')"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
                              :exportExcel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="date" v-model="datatable.queryParams.filter.start_date">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="date" v-model="datatable.queryParams.filter.end_date">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('status')">
                            <multi-selectbox
                                             :multiple="false"
                                             v-model="datatable.queryParams.filter.status"
                                             :options="[{value:'not_moved', text:'Virmanlanmadı'}, {value:'moved', text:'Virmanlandı'}]"
                            >

                            </multi-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('explanation')">
                            <b-form-input v-model="datatable.queryParams.filter.explanation">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>
            <CommonModal id="paymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('unidentified_payments') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="paymentForm">
                        <div class="row" v-if="paymentForm">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="period_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('academic_year') }}</label>
                                        <payment-year-selectbox
                                            :until-active="true"
                                            :validate-error="errors[0]"
                                            v-model="paymentForm.year_id"></payment-year-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input
                                            type="number"
                                            step=".01"
                                            v-model="paymentForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox
                                            :validateError="errors[0]"
                                            v-model="paymentForm.currency">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="payment_date" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input
                                            type="date"
                                            v-model="paymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input

                                            type="number"
                                            step=".0001"
                                            v-model="paymentForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]"
                                                        v-model="paymentForm.bank_id" ref="otherPaymentBank">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="payment_type_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]"
                                                                 type="wire" :is_bank_required="true"
                                                                 :bank_id="paymentForm.bank_id"
                                                                 v-model="paymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules=""
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input
                                            v-model="paymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePayment" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

            <CommonModal id="movePaymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('move_payment').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <div class="col-12 mt-2">
                            <b-form-group :label="$t('student_number')">
                                <div class="input-group">
                                    <input type="text" v-model="movePaymentForm.student_number" class="form-control" aria-describedby="button-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" @click="findStudentToMove(movePaymentForm, true)" type="button" id="button-addon2">{{$t('find_student').toUpper()}}</button>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="col-12 mt-2" v-if="studentsToMove.length>0">
                            <b-form-group :label="$t('program')">
                                <multi-selectbox :allow-empty="false"
                                                 :multiple="false"
                                                 v-model="movePaymentForm.id"
                                                 :options="studentsToMove"
                                >

                                </multi-selectbox>
                            </b-form-group>
                        </div>
                        <div class="col-12 mt-2" v-if="paymentPlansToMove.length>0">
                            <b-form-group :label="$t('payment_plan')">
                                <multi-selectbox :allow-empty="false"
                                                 :multiple="false"
                                                 v-model="movePaymentForm.payment_plan_id"
                                                 :options="paymentPlansToMoveComputed"
                                >

                                </multi-selectbox>
                            </b-form-group>
                        </div>
                        <div class="col-12" v-if="movePaymentForm.payment_plan_id">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('transaction_date') }}</label>
                                <b-form-input
                                    type="date"
                                    v-model="movePaymentForm.transaction_date"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-12" v-if="movePaymentForm.payment_plan_id">
                            <b-button type="submit" @click="saveMovePayment" variant="primary">{{
                                    $t('save')
                                }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import UnidentifiedPaymentService from "@/services/UnidentifiedPaymentService";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";
import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import CurrencyRateService from "@/services/CurrencyRateService";
import BankSelectbox from "@/components/interactive-fields/BankSelectbox";
import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox.vue";
import PaymentYearSelectbox from "@/components/interactive-fields/PaymentYearSelectbox.vue";
import StudentService from "@/services/StudentService";
import PaymentPlanService from "@/services/PaymentPlanService";
import PaymentService from "@/services/PaymentService";
import RefundRequestService from "@/services/RefundRequestService";

export default {
    components: {
        PaymentYearSelectbox,
        YearsSelectbox,
        BankSelectbox,
        PaymentTypesSelectbox,
        CurrencySelectbox,
        MultiSelectbox,
        PaymentPeriodsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox,
        CommonModal,
        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t("unidentified_payments"),
        };
    },
    data() {
        return {
            paymentForm: null,
            terminalOptions: [],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass:
                            "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "unidentifiedpayment_update",
                                callback: (row) => {
                                    this.showPayment(row);
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "unidentifiedpayment_delete",
                                callback: (row) => {
                                    this.deletePayment(row.id);
                                },
                            },
                            {
                                text: this.$t("move"),
                                class: "ri-drag-move-2-fill",
                                permission: "unidentifiedpayment_delete",
                                show: (row) => {
                                    return row.status=='not_moved';
                                },
                                callback: (row) => {
                                    this.movePayment(row);
                                },
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: 'academic_year',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("amount"),
                        field: 'amount',
                        formatFn: this.formatNumber,
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("currency"),
                        field: 'currency',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("currency_rate"),
                        field: 'currency_rate',
                        formatFn: this.formatNumber,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("amount_tl"),
                        field: 'amount_tl',
                        formatFn: this.formatNumber,
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("payment_date"),
                        field: 'payment_date',
                        formatFn: this.formatDate,
                        thClass: 'width-150',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("payment_type"),
                        field: 'payment_type_name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("virman_date"),
                        field: 'transfer_date',
                        formatFn: this.formatDate,
                        thClass: 'width-150',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: 'number',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: 'surname',
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
            studentsToMove:[],
            paymentPlansToMove:[],
            movePaymentForm:{}
        };
    },
    methods: {
        createFormShow() {
            this.formId = null
            this.paymentForm = {};
            this.$bvModal.show('paymentModal');
        },
        callService(config) {
            return UnidentifiedPaymentService.getAll(config)
        },
        getRows() {
            this.$refs.datatable.getRows();
        },
        async savePayment() {
            const isValid = await this.$refs.paymentForm.validate();
            if (isValid) {
                if (this.paymentForm.id > 0) {
                    UnidentifiedPaymentService.update(this.paymentForm.id, this.paymentForm)
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('paymentModal');
                            this.getRows();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.paymentForm);
                        });
                } else {
                    UnidentifiedPaymentService.store(this.paymentForm)
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('paymentModal');
                            this.getRows();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.paymentForm);
                        });
                }
            }
        },
        showPayment(row = {}) {
            this.$bvModal.show('paymentModal');
            this.paymentForm = row;
        },
        deletePayment(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        UnidentifiedPaymentService.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.getRows();
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                })
        },
        setCurrencyRate(obj) {
            var date = null;
            if (typeof obj.payment_date != 'undefined') {
                date = obj.payment_date;
            }
            if (typeof obj.refund_date != 'undefined') {
                date = obj.refund_date;
            }

            if (!obj || obj.currency == 'TRY') {
                this.$set(obj, 'currency_rate', 1);
                return;
            }

            if (!date) {
                return;
            }
            CurrencyRateService.getByDate(date, obj.currency)
                .then(response => {
                    if (response.data.data && response.data.data.banknote_selling) {
                        this.$set(obj, 'currency_rate', response.data.data.banknote_selling);
                    }
                });
        },
        findStudentToMove(form, allowSame=false){
            if(!form.student_number){
                return;
            }

            this.studentsToMove=[];
            StudentService.studentSearch({params: {student_number: form.student_number}})
                .then(response => {
                    var id=1;
                    response.data.data.students.forEach(item=>{
                        if(item.student_program_id==this.student_program_id && !allowSame){
                            return;
                        }
                        this.studentsToMove.push({id:item.student_program_id, type:'student', value: id++, text: item.name+' '+item.surname + ' - '+ this.getLocaleText(item, 'program_name') + ' (Öğrenci) ' + this.getLocaleText(item, 'major_type_name') + ' / ' + this.getLocaleText(item, 'student_status_name')});
                    });
                    response.data.data.registrations.forEach(item=>{
                        if(item.registration_id==this.registration_id && !allowSame){
                            return;
                        }
                        this.studentsToMove.push({id:item.registration_id, type:'registration', value: id++, text: item.name+' '+item.surname + ' - '+ this.getLocaleText(item, 'program_name') + ' (Kayıt) ' + item.academic_year});
                    });
                });
        },
        saveMovePayment(){
            if(!this.movePaymentForm.payment_plan_id || !this.movePaymentForm.transaction_date){
                return;
            }

            UnidentifiedPaymentService.move(this.selectedPayment.id, {
                payment_plan_id: this.movePaymentForm.payment_plan_id,
                transaction_date: this.movePaymentForm.transaction_date
            })
                .then(response=>{
                    this.toast(response.data);
                    this.$bvModal.hide('movePaymentModal');
                    this.loadData();
                })
                .catch(e=>{
                    this.showErrors(e);
                });
        },
        movePayment(payment){
            this.movePaymentForm = {};
            this.selectedPayment = payment;
            this.$bvModal.show('movePaymentModal');
        },
    },
    watch: {
        'paymentForm.bank_id': function (selected) {
        },
        'paymentForm.payment_date'(newVal) {
            this.setCurrencyRate(this.paymentForm);
        },
        'paymentForm.currency'(newVal) {
            this.setCurrencyRate(this.paymentForm);
        },
        'movePaymentForm.id'(newVal){
            if(!this.movePaymentForm.id){
                return;
            }
            var id=this.movePaymentForm.id;
            var filtered = this.studentsToMove.filter(function(item){
                return item.value==id;
            });

            var filter={};
            if(filtered[0].type=='student'){
                filter.student_program_id = filtered[0].id;
            }
            else if(filtered[0].type=='registration'){
                filter.registration_id = filtered[0].id;
            }
            const config = {
                params: {
                    filter: filter,
                    include: ['prices.product','prices.period'],
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            this.paymentPlansToMove=[];
            PaymentPlanService.find(config)
                .then(response=>{
                    this.paymentPlansToMove = response.data.data;
                })
                .catch(e=>{
                    this.showErrors(e);
                });
        }
    },
    computed: {
        paymentPlansToMoveComputed(){
            var arr=[];
            this.paymentPlansToMove.forEach(item=>{
                arr.push({
                    value: item.id,
                    text: item.prices.period.code+' - '+item.prices.product.name+' - '+this.$options.filters.formatToCurrency(item.price, item.currency)
                });
            });
            return arr;
        },
    }
};
</script>

